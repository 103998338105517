import React, { useState, useEffect } from 'react';
import google from '@img/google.webp';
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import logo from '@img/logo.webp';
import * as bootstrap from 'bootstrap';
import ToastModal from "../components/ToastModal";
import $ from 'jquery';
import { loginGoogleUrl } from '../util/constants';
import { tracking } from '../api';

import '@styles/Login.scss';

const Login = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [message, setMessage] = useState(null);

    const fetchTrackingData = async () => {
        try {
            await tracking({ page: 'login' })
        } catch (error) {
            console.error('Error al obtener los datos:', error);
        }
    };

    useEffect(() => {
        window.dataLayer.push({
            event: "page_view"
        })
    }, [location]);

    const fetchData = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        const message = queryParams.get('message');
        if(message){
            setMessage(message)
            if (message) {
                const toastEl = $('#liveToast')[0];
                if (toastEl) {
                    let toast = new bootstrap.Toast(toastEl, { delay: 8000 });
                    toast.show();
                    navigate('/login');
                }
            }
        }
    };

    useEffect(() => {
        fetchData();
        fetchTrackingData();
    }, []);



    return (
        <div className="Login">
            <div className="Content">
                <div className='header'>
                    <img src={logo} alt="firulife-logo"/>
                </div>
                <p className='title'>Iniciar sesión en Firulife</p>
                <div className='body'>
                    <div className='login-google' onClick={() =>  window.location = loginGoogleUrl}>
                        <img src={google} alt="firulife-google"/>
                        Iniciar sesión con google
                    </div>
                </div>
                {/* <div className='register'>
                    ¿No tienes cuenta?  <NavLink className="link_register" to='/register'>Regístrate ahora</NavLink>
                </div> */}
                <div className='go-home'>
                    <NavLink className="link_register" to='/'>Ir al home</NavLink>
                </div>
            </div>
            <ToastModal message={message}/>
        </div>
    )
}

export default Login;